
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full grid md:grid-cols-2 grid-cols-1 md:justify-center items-center pt-[100px] md:pt-[150px] pb-[20px] md:pb-[100px]">
          <div>
            <div className="w-full flex flex-col justify-center items-center">
              <h1 className="text-white text-center md:text-start text-[35px] md:text-[60px]">
                {title}
              </h1>
              <p className="text-white text-center md:text-start">
                {texts}
              </p>
            </div>
            <div className="w-full flex justify-center  md:justify-start py-5">
              <ButtonContent btnStyle={'three'} />
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F15%20Años.png?alt=media&token=5b349642-3cbf-48cf-b48d-ce505ac0d133"}
              alt="chapayears"
              loading="lazy"
              className="h-[155px] w-[151px] md:h-[310px] md:w-[303px] object-cover"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
